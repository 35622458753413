import React, { StrictMode } from "react";
import { BrowserRouter as Router } from "react-router-dom";
// All Css Here
import "bootstrap/dist/css/bootstrap.min.css";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "react-modal-video/css/modal-video.min.css";
import "./src/assets/css/plugins.css";
import "./src/assets/scss/style.scss";
import "./src/assets/scss/customStyle.scss";

export const wrapRootElement = ({ element }) => {
  return (
    <StrictMode>
      <Router>{element}</Router>
    </StrictMode>
  );
};
