exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-blog-details-js": () => import("./../../../src/pages/blog-details.js" /* webpackChunkName: "component---src-pages-blog-details-js" */),
  "component---src-pages-blog-js": () => import("./../../../src/pages/blog.js" /* webpackChunkName: "component---src-pages-blog-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-devops-js": () => import("./../../../src/pages/devops.js" /* webpackChunkName: "component---src-pages-devops-js" */),
  "component---src-pages-experience-business-development-js": () => import("./../../../src/pages/experience-business-development.js" /* webpackChunkName: "component---src-pages-experience-business-development-js" */),
  "component---src-pages-experience-e-commerce-js": () => import("./../../../src/pages/experience-e-commerce.js" /* webpackChunkName: "component---src-pages-experience-e-commerce-js" */),
  "component---src-pages-experience-erp-js": () => import("./../../../src/pages/experience-erp.js" /* webpackChunkName: "component---src-pages-experience-erp-js" */),
  "component---src-pages-experience-iot-js": () => import("./../../../src/pages/experience-iot.js" /* webpackChunkName: "component---src-pages-experience-iot-js" */),
  "component---src-pages-experience-js": () => import("./../../../src/pages/experience.js" /* webpackChunkName: "component---src-pages-experience-js" */),
  "component---src-pages-experience-lms-js": () => import("./../../../src/pages/experience-lms.js" /* webpackChunkName: "component---src-pages-experience-lms-js" */),
  "component---src-pages-experience-ott-js": () => import("./../../../src/pages/experience-ott.js" /* webpackChunkName: "component---src-pages-experience-ott-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-iot-app-control-js": () => import("./../../../src/pages/iot-app-control.js" /* webpackChunkName: "component---src-pages-iot-app-control-js" */),
  "component---src-pages-microservices-architecture-js": () => import("./../../../src/pages/microservices-architecture.js" /* webpackChunkName: "component---src-pages-microservices-architecture-js" */),
  "component---src-pages-native-hybrid-apps-js": () => import("./../../../src/pages/native-hybrid-apps.js" /* webpackChunkName: "component---src-pages-native-hybrid-apps-js" */),
  "component---src-pages-web-api-automation-js": () => import("./../../../src/pages/web-api-automation.js" /* webpackChunkName: "component---src-pages-web-api-automation-js" */),
  "component---src-pages-web-development-js": () => import("./../../../src/pages/web-development.js" /* webpackChunkName: "component---src-pages-web-development-js" */)
}

